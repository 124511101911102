import React, { useState, useEffect } from "react";
import MyButton from "./UI/button/MyButton";
import MyInput from "./UI/input/MyInput";
import MySelect from "./UI/select/MySelect";
import Loader from "./UI/Loader/Loader";
import PostService from "../API/PostService";
import { useFetching } from "../hooks/useFetching";

const ReupPostForm = ({ ...props }) => {
  const [selectedSite, setSelectedSite] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [reupPostLink, setReupPostLink] = useState("");

  const [sendReupPost, isLoading, error] = useFetching(
    async (post, site, selectedCat, selectedStatus) => {
      const response = await PostService.sendReupPost(
        post,
        site,
        selectedCat,
        selectedStatus
      );
      setReupPostLink(response.data.link);
    }
  );

  const [getCategoriesBySite, isCatLoading, catError] = useFetching(
    async (site) => {
      const response = await PostService.getCategoriesBySite(site);
      const categories = response.data.map((category) => {
        return { value: category.id, name: category.name };
      });
      setCategoriesList(categories);
    }
  );

  const addNewPost = async (e) => {
    e.preventDefault();
    await sendReupPost(props.post, selectedSite, selectedCat, selectedStatus);
  };

  useEffect(() => {
    async function fetchData() {
      if (selectedSite) {
        await getCategoriesBySite(selectedSite);
        // categories = categories.data.map(category =>{
        //     return { value: category.id, name: category.name }
        // })
        // setCategoriesList(categories)
      }
    }
    fetchData();
  }, [selectedSite]); // Or [] if effect doesn't need props or state

  const LIST_SITES = [
    {
      value: "DAILYNEW_ZULULION",
      name: "DAILYNEW_ZULULION - dailynew.zululion.com",
    },
    {
      value: "VIRAL_ZULULION",
      name: "VIRAL_ZULULION - viral.zululion.com",
    },
    {
      value: "FOXNEWS_THONGTINLUAT",
      name: "FOXNEWS_THONGTINLUAT - foxnews.thongtinluat.com",
    },
    {
      value: "FOX_THONGTINLUAT",
      name: "FOX_THONGTINLUAT - fox.thongtinluat.com",
    },
    {
      value: "RETRO_XINLOC",
      name: "RETRO_XINLOC - https://retro.xinloc.com",
    },
    {
      value: "SPOILER_XINLOC",
      name: "SPOILER_XINLOC - https://spoiler.xinloc.com",
    },
    {
      value: "FILM_XINLOC",
      name: "FILM_XINLOC - https://film.xinloc.com",
    },
    {
      value: "CELEBRITY_ZULULION",
      name: "CELEBRITY_ZULULION - celebrity.zululion.com",
    },
    {
      value: "DAILYNEWS_ZULULION",
      name: "DAILYNEWS_ZULULION - dailynews.zululion.com",
    },
    {
      value: "CELEBRITY_XINLOC",
      name: "CELEBRITY_XINLOC - https://celebrity.xinloc.com",
    },
    {
      value: "WOLF_ZULULION",
      name: "WOLF_ZULULION - https://wolf.zululion.com",
    },
    {
      value: "FOXNEWS_XINLOC",
      name: "FOXNEWS_XINLOC - https://foxnews.xinloc.com",
    },
    { value: "NEWS_XINLOC", name: "NEWS_XINLOC - news.xinloc.com" },
    {
      value: "ROYALS_XINLOC",
      name: "ROYALS_XINLOC - http://royals.xinloc.com",
    },
    {
      value: 'BULLETINUS_COM',
      name: 'BULLETINUS_COM - https://bulletinus.com'
    },
    {
      value: 'NEWSUSDAILY_COM',
      name: 'NEWSUSDAILY_COM - https://newsusdaily.com'
    },
    {
      value: 'QUICKNEWDAILY_COM',
      name: 'QUICKNEWDAILY_COM - https://quicknewdaily.com'
    },
    {
      value: 'ROCKETNEWSUS_COM',
      name: 'ROCKETNEWSUS_COM - https://rocketnewsus.com'
    },
    {
      value: 'SNAPNEWSDAILY_COM',
      name: 'SNAPNEWSDAILY_COM - https://snapnewsdaily.com'
    },
    {
      value: 'NEWSJET4U_COM',
      name: 'NEWSJET4U_COM - https://newsjet4u.com'
    },
    {
      value: "FOXXINLOC_COM",
      name: "FOXXINLOC_COM - https://fox.xinloc.com"
    },
  { 
    value: "EXCLUSIVENEWSNOW_COM", 
    name: "EXCLUSIVENEWSNOW_COM - exclusivenewsnow.com" 
  },
  { 
    value: "EVENINGNEWSRECAP_COM", 
    name: "EVENINGNEWSRECAP_COM - eveningnewsrecap.com" 
  },
  { 
    value: "NEWSIN24H_COM", 
    name: "NEWSIN24H_COM - newsin24h.com" 
  },
  {
    value: "TOPDAILYALERTS_COM",
    name: "TOPDAILYALERTS_COM - topdailyalerts.com"
  },
  {
    value: "SPORTNEWSZULULION_COM",
    name: "SPORTNEWSZULULION_COM - sportnews.zululion.com"
  },
  {
    value: "FILMZULULION_COM",
    name: "FILMZULULION_COM - https://film.zululion.com"
  },
  {
    value: "FOXNEWS_ZULULION_COM",
    name: "FOXNEWS_ZULULION_COM - https://foxnews.zululion.com/"
  },
  {
    value: "FOX_ZULULION_COM",
    name: "FOX_ZULULION_COM - https://fox.zululion.com/"
  },
  {
    value: "NEWSWAVE24H_COM",
    name: "NEWSWAVE24H_COM - https://newswave24h.com/"
  },
  {
    value: "FRESHBRIEFS24H_COM",
    name: "FRESHBRIEFS24H_COM - https://freshbriefs24h.com/"
  },
  {
    value: "NEWSBLITZLIVE_COM",
    name: "NEWSBLITZLIVE_COM - https://newsblitzlive.com/"
  },
  {
    value: "NEWSHOURLIVE_COM",
    name: "NEWSHOURLIVE_COM - https://newshourlive.com/"
  },
  {
    value: "QUICKNEWSBRIEF_COM",
    name: "QUICKNEWSBRIEF_COM - https://quicknewsbrief.com/"
  },
  ];

  const statusesList = [
    {
      value: "draft",
      name: "Draft",
    },
    {
      value: "publish",
      name: "Publish",
    },
  ];
  return (
    <div className="reup-post">
      <h3>Re-up post options:</h3>
      <form>
        <label> Select target site: </label>
        <MySelect
          value={selectedSite}
          onChange={(selectedSite) => setSelectedSite(selectedSite)}
          defaultValue="Select site to reup..."
          options={LIST_SITES}
          isRequired={true}
        />

        <label> Select category: </label>
        <MySelect
          value={selectedCat}
          onChange={(selectedCat) => setSelectedCat(selectedCat)}
          defaultValue="Select category to reup..."
          options={categoriesList}
          isRequired={true}
        />

        <label> Select status: </label>
        <MySelect
          value={selectedStatus}
          onChange={(selectedStatus) => setSelectedStatus(selectedStatus)}
          defaultValue="Select site to reup..."
          options={statusesList}
          isRequired={true}
        />
        {/* <MyInput 
            // value={post.title}
            // onChange={e => setPost({...post, title: e.target.value})}
            type='text' placeholder='Title of the post' />

            <MyInput 
            // value={post.body}
            // onChange={e => setPost({...post, body: e.target.value})}
            type='text' placeholder='Description of the post' /> */}

        <MyButton disabled={!selectedSite} onClick={addNewPost}>
          Create the post
        </MyButton>
      </form>

      {isCatLoading || isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <Loader />
        </div>
      ) : null}

      {catError && (
        <h3>
          Can not get list of categories. Please contact to supporter to check
          it...
        </h3>
      )}

      {error && (
        <h3>
          Can not create the post. Please contact to supporter to check it...
        </h3>
      )}
      {reupPostLink ? (
        <div className="reup-post-result" style={{ margin: "10px" }}>
          <label>Result link: </label>
          {reupPostLink ? (
            <a href={reupPostLink} target="__blank">
              {" "}
              {reupPostLink}{" "}
            </a>
          ) : (
            ""
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ReupPostForm;
