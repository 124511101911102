// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_loader__1MjUl {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    border: 3px dashed teal;\n    animation: Loader_rotate__3fyxQ 1s infinite linear;\n}\n\n@keyframes Loader_rotate__3fyxQ {\n    from {\n        transform: rotate(0deg) scale(1);\n    }\n    to {\n        transform: rotate(360deg) scale(1.5)\n    }\n}", "",{"version":3,"sources":["webpack://src/components/UI/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,kDAAoC;AACxC;;AAEA;IACI;QACI,gCAAgC;IACpC;IACA;QACI;IACJ;AACJ","sourcesContent":[".loader {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    border: 3px dashed teal;\n    animation: rotate 1s infinite linear;\n}\n\n@keyframes rotate {\n    from {\n        transform: rotate(0deg) scale(1);\n    }\n    to {\n        transform: rotate(360deg) scale(1.5)\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "Loader_loader__1MjUl",
	"rotate": "Loader_rotate__3fyxQ"
};
export default ___CSS_LOADER_EXPORT___;
