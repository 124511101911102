// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyModal_myModal__3wunW {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    display: none;\n    background: rgba(0,0,0, 0.3)\n}\n\n.MyModal_myModalContent__3NJ2c {\n    padding: 25px;\n    background: white;\n    border-radius: 16px;\n    min-width: 250px;  \n}\n\n/*.class1.class2 { Описание правил стиля }\nстиль применяется только для элементов, у которых одновременно заданы классы class1 и class2,\nт. е. в коде HTML используется конструкция <E class=\"class1 class2>*/\n.MyModal_myModal__3wunW.MyModal_active__2TUBd { \n    display: flex;\n    justify-content: center;\n    align-items: center; \n}", "",{"version":3,"sources":["webpack://src/components/UI/MyModal/MyModal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;IACP,aAAa;IACb;AACJ;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;;oEAEoE;AACpE;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".myModal {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    display: none;\n    background: rgba(0,0,0, 0.3)\n}\n\n.myModalContent {\n    padding: 25px;\n    background: white;\n    border-radius: 16px;\n    min-width: 250px;  \n}\n\n/*.class1.class2 { Описание правил стиля }\nстиль применяется только для элементов, у которых одновременно заданы классы class1 и class2,\nт. е. в коде HTML используется конструкция <E class=\"class1 class2>*/\n.myModal.active { \n    display: flex;\n    justify-content: center;\n    align-items: center; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myModal": "MyModal_myModal__3wunW",
	"myModalContent": "MyModal_myModalContent__3NJ2c",
	"active": "MyModal_active__2TUBd"
};
export default ___CSS_LOADER_EXPORT___;
