import React from 'react'
import MyButton from './UI/button/MyButton'
import {useHistory} from 'react-router-dom'


const PostItem = (props) => {
    const router = useHistory()


const formatDate = (date) => {
    date = new Date(date)
    const formatter = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      
      const parts = formatter.formatToParts(date);
      const formattedDateTime = parts.map(part => part.value).join('');

    return date ? formattedDateTime : '--/--/----';
  };
        
    return (
        <div className="post">
            <div className="post_content">
                <strong>{props.post.id} {props.post.title}</strong>
                <div>{props.post.body}</div>     
            </div>
            <div className="post_btns">
                <MyButton style={{marginRight:10}} onClick={() => router.push(`/posts/${props.post._id}`)}>Open</MyButton>
                <MyButton onClick={() => props.remove(props.post)}>Delete</MyButton>
            </div>

            <div className="post_more">
                <a href={props.post.link}>{props.post.link}</a>
                <span>{ formatDate(props.post.created_at) }</span>
            </div>
        </div>
    )
}

export default PostItem