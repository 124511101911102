import React from 'react'
import {getPagesArray, getPaginationRange} from '../../../utils/pages'



const Pagination = ({totalPages, page, changePage}) => {
    let pagesArray = getPagesArray(totalPages)
    let { startRange, endRange } = getPaginationRange(totalPages, page)
    
    return (
        <div class="center">
        <div className="page_wrapper pagination">

      <button onClick={() => changePage(1)} disabled={page === 1}>
        First
      </button>
      <button onClick={() => changePage(page - 1)} disabled={page === 1}>
        Previous
      </button>

      {Array.from({ length: endRange - startRange + 1 }, (_, index) => {
        const page_index = startRange + index;
        return (
          <button
            key={page_index}
            onClick={() => changePage(page_index)}
            className={page_index === page ? 'active page page_current' : ''}
          >
            {page_index}
          </button>
        );
      })}

      <button onClick={() => changePage(page + 1)} disabled={page === totalPages}>
        Next
      </button>
      <button onClick={() => changePage(totalPages)} disabled={page === totalPages}>
        Last
      </button>
    </div>

            {/* {pagesArray.map(p =>
            <span
                onClick={() => changePage(p)}
                key={p} className={page === p ? 'page page_current' : 'page'}>{p}</span>
            )} */}

            {/* <span
                onClick={() => changePage(page - 1)}
                className='page page_previous' disabled={page === 1}> Previous
            </span>

            <span
                onClick={() => changePage(page)}
                className='page page_current'>  { page }
            </span>

            <span
                onClick={() => changePage(page + 1)}
                className='page page_next' disabled={page === totalPages}>  Next
            </span> */}
       </div> 
    )
}

export default Pagination