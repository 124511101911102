export const getPageCount = (totalCount, limit) => {
    return Math.ceil(totalCount/limit)
}

export const getPagesArray = (totalPages) => {
    let results = []
    for(let i=0; i<totalPages; i++){
        results.push(i+1)
    }
    return results
}

export const getPaginationRange = (totalPages, page) => {
    const rangeSize = 10;
    const midRange = Math.floor(rangeSize / 2);
    let startRange = page - midRange;
    let endRange = page + midRange;

    if (startRange <= 0) {
      startRange = 1;
      endRange = rangeSize;
    }

    if (endRange > totalPages) {
      endRange = totalPages;
      startRange = endRange - rangeSize + 1;
      if (startRange <= 0) {
        startRange = 1;
      }
    }

    return { startRange, endRange };
  };