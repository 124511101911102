import React, {useState, useMemo, useRef, useEffect} from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/App.css'
import PostList from '../components/PostList'
import PostForm from '../components/PostForm'
import PostFilter from '../components/PostFilter'
import MyModal from '../components/UI/MyModal/MyModal'
import MySelect from '../components/UI/select/MySelect'
import {usePosts} from '../hooks/usePosts'
import PostService from '../API/PostService'
import Loader from '../components/UI/Loader/Loader'
import {useFetching} from '../hooks/useFetching'
import {getPageCount} from '../utils/pages'
import Pagination from '../components/UI/pagination/Pagination.jsx'

function Posts(){
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);


  const [posts, setPosts] = useState([])
  const [listSites, siteListSites] = useState([])

  const [filter, setFilter] = useState({sort: '', query: ''})
  const [searchQuery, setSearchQuery] = useState('')
  const [searchTitle, setSearchTitle] = useState('')
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const [modal, setModal] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const sortedAndSearchedPosts = usePosts(posts, filter.sort, filter.query)
  
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const handleTitleInputChange = (event) => {
    setSearchTitle(event.target.value);
  }

  const handleSearch = async (e) => {
    e.preventDefault()

    try {
      setPage(1)

      console.log(limit, page, searchTitle, searchQuery, selectedSite, fromDate, toDate)

      const response = await PostService.getAll(limit, page, searchTitle, searchQuery, selectedSite, fromDate, toDate)
      setPosts(response.data.items)
      setTotalPages(response.data.total_pages)

      const queryParams = {
        title: searchTitle,
        q: searchQuery,
        from_date: fromDate ? formatDate(fromDate) : '',
        to_date: toDate ? formatDate(toDate) : '',
        site: selectedSite
      };
  
      const queryString = new URLSearchParams(queryParams).toString();
      history.push(`/posts?${queryString}`);
    } catch (error) {
      console.error('Error searching:', error);
    }
  }
  
  const [fetchSitesList, isSitesList, sitesListError] = useFetching(async () => {
     const data = [
        "365newsinfo.com",
        "a-z-animals.com",
        "animatedtimes.com",
        "cosmopolitanme.com",
        "hot.mamamath.net",
        "hotnewsmm.xyz",
        "iwmbuzz.com",
        "justbartanews.com",
        "lexnau.com",
        "mstfootball.com",
        "nybreaking.com",
        "nyotimes.com",
        "stylecaster.com",
        "usmagazine.com"
        ]
    // const response = await PostService.getSites()
    let sites = data.map(item => {
      return {
        value: item,
        name: item
      }
    })
  
    siteListSites([{
      value: 'All',
      name: 'All'
    }, ...sites])
  })

  const [fetchPosts, isPostsLoading, postError] = useFetching(async (limit, page, title, query, site, from_date, to_date) => {
    const response = await PostService.getAll(limit, page, title, query, site, from_date, to_date)
    setPosts(response.data.items)
    setTotalPages(response.data.total_pages)
  })

  const converFromDate = (inputDate) => {
    if (!inputDate) {
      return null
    }
    const parts = inputDate.split('/');
    const startDate = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10),
      0, 0, 0, 0
    );
    const isoStartDate = startDate.toISOString();
    return isoStartDate
  }

  const converToDate = (inputDate) => {
    if (!inputDate) {
      return null
    }
    const parts = inputDate.split('/');
    const endDate = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10),
      23, 59, 59, 999
    );

    const isoEndDate = endDate.toISOString();
    return isoEndDate
  }
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('q') || '';
    const title = queryParams.get('title') || '';
    const from_date = queryParams.get('from_date') || '';
    const to_date = queryParams.get('to_date') || '';
    const site = queryParams.get('site') || '';
    setSearchTitle(title)
    setSearchQuery(query)
    setSelectedSite(site)

    if (from_date) {
      const [day, month, year] = from_date.split('/');
      const parsedFromDate = new Date(year, month - 1, day);
      setFromDate(parsedFromDate)
    }

    if (to_date) {
      const [day, month, year] = to_date.split('/');
      const setSelectedToDate = new Date(year, month - 1, day);
      setToDate(setSelectedToDate)
    }

    if (location.search) {
      fetchPosts(limit, page, title, query, site, converFromDate(from_date), converToDate(to_date))
    } else {
      fetchPosts(limit, page)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    fetchSitesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const removePost = (post) => {
    setPosts(posts.filter(p => p.id !== post.id))
  }

  const changePage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setPage(page)
      fetchPosts(limit, page, searchTitle, searchQuery, selectedSite, fromDate, toDate)
    }
  }

  const formatDate = (date) => {
    return date ? new Intl.DateTimeFormat('en-GB').format(date) : '--/--/----';
  };

  const marginTopStyle = {
    marginTop: '20px',
  };
  const datePickerStyles = {
    padding: '10px',
  };

  const inputStyles = {
    padding: '8px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ccc'
  };
  return (
    <div className='App' style={marginTopStyle}>
      {
        
<form id="searchForm">
<div class="row">
    <div class="date-container">
    <label for="searchInputTitle">Title:</label>
    <input type="text" id="searchInputTitle" className="search-input" placeholder="Search..." value={searchTitle}
        onChange={handleTitleInputChange} />
        </div>
  </div>
  <div class="row">
    <div class="date-container">
    <label for="searchInput">Keyword:</label>
    <input type="text" id="searchInput" className="search-input" placeholder="Search..." value={searchQuery}
        onChange={handleInputChange} />
        </div>
  </div>
  <div class="row">
    <div class="date-container">
      <label> Select target site: </label>
      <MySelect
            value={selectedSite}
            onChange={selectedSite => setSelectedSite(selectedSite)}
            defaultValue='Select site'
            options = {listSites}        
            isRequired = { true  }
            />
    </div>
  </div>
  <div class="row">
        <div class="date-container">
          <label for="formDateField">From Date:</label>
          <DatePicker
            id="formDateField"
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="--/--/----"
            style={datePickerStyles}
            customInput={<input style={inputStyles} />}
          />

          <label for="toDateField">To Date:</label>
          <DatePicker
            id="toDateField"
            selected={toDate}
            onChange={(date) => setToDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="--/--/----"
            style={datePickerStyles}
            customInput={<input style={inputStyles} />}
          />
          </div>
  </div>
  <div class="row">
    <div class="date-container">
    <button className="search-button" onClick={handleSearch}>Search</button>
    </div>
  </div>
</form>
}
     {
       postError &&
        <h1>Error ${postError}</h1>
     }
     {isPostsLoading
      ? <div style={{display:'flex', justifyContent:'center', marginTop:50}}><Loader /></div>
      : <PostList posts={sortedAndSearchedPosts} remove={removePost} title='List of the posts' />
     }
     <Pagination page={page} totalPages={totalPages} changePage={changePage}/>
     
    </div>
  )
}

export default Posts
